






























import {Component, Prop, Vue} from "vue-property-decorator";
import {Customer} from "@/models/customer";
import {Machine} from "@/models/machine";

@Component({})
export default class MachineHeader extends Vue {

  @Prop({ default: new Machine({}) })
  public machine!: Machine;
}

